body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.button-account-bar button{
  padding: 0px !important;
  margin-top: 20px !important;
}

.image-upload-icon img{
  width: 300px !important;
  max-width: initial!important;
  height: auto !important;
}

@font-face {
  font-family: 'Montserrat-medium';
  src: url('./assets/fonts/Montserrat-Medium.eot');
  src: url('./assets/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Montserrat-Medium.woff2') format('woff2'),
      url('./assets/fonts/Montserrat-Medium.woff') format('woff'),
      url('./assets/fonts/Montserrat-Medium.ttf') format('truetype'),
      url('./assets/fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}